import React from 'react'
import {Box, Typography, Button } from '@mui/material';
import image from '../assets/images/gym.jpg'
import { Apple } from '@mui/icons-material';

const HeroBanner = () => {
  return (
    <Box 
      sx={{
        mt: { lg: '212px', xs: '70px' }, 
        ml: { sm: '50px' },
        textAlign: { xs: 'center', lg: 'left' } // Center text on mobile screens
      }} 
      position="relative" 
      p="20px"
    >
      <Typography 
        color="#006161" 
        fontWeight="600" 
        fontSize="26px" 
        className="hero-banner-text"
      >
        PR Logs
      </Typography>
      <Typography 
        fontWeight={700} 
        sx={{ fontSize: { lg: '44px', xs:'40px' } }} 
        mb="23px" 
        mt="30px"
        className="hero-banner-text"
      >
        Better workout <br/> Notes
      </Typography>
      <Typography 
        fontSize="22px" 
        lineHeight="35px" 
        mb={4} 
        className="hero-banner-text"
      >
        Now available on the iOS App Store.
      </Typography>

      <Button 
        variant='contained' 
        startIcon={<Apple />} 
        sx={{ 
          backgroundColor: '#006161', 
          padding: '10px', 
          mt: { xs: '20px', lg: '0' } 
        }} 
        href="https://apps.apple.com/app/pr-logs-better-workout-notes/id6566186250"
        className="download-button"
      >
        Download Now
      </Button>

      <img 
          src={image} 
        alt="" 
        className="hero-banner-img"
      />

      <Typography
        fontWeight={600}
        color='#006161'
        sx={{
          opacity: 0.1,
          display: { lg:'block', xs:'none' },
          fontSize: '200px'
        }}
      >
        Exercise
      </Typography>
    </Box>
  );
};



export default HeroBanner
