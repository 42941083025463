import React from 'react';

import fullbody from '../../../assets/images/fullBody.png';
import abs from '../../../assets/images/body-parts/abs.png';
import back from '../../../assets/images/body-parts/back.png';
import biceps from '../../../assets/images/body-parts/biceps.png';
import calves from '../../../assets/images/body-parts/calves.png';
import chest from '../../../assets/images/body-parts/chest.png';
import forearms from '../../../assets/images/body-parts/forearms.png';
import glutes from '../../../assets/images/body-parts/glutes.png';
import hamstring from '../../../assets/images/body-parts/hamstring.png';
import quads from '../../../assets/images/body-parts/quads.png';
import shoulder from '../../../assets/images/body-parts/shoulder.png';
import traps from '../../../assets/images/body-parts/traps.png';
import triceps from '../../../assets/images/body-parts/triceps.png';
import heart from '../../../assets/images/body-parts/cardio.png';

const BodyPartImage = ({ bodyPart, size }) => {
  const getImageForBodyPart = (bodyPart, size) => {

    console.log("showing not image",bodyPart)

    if (!bodyPart) return fullbody; // Default image

console.log("showing image",bodyPart)

    bodyPart = bodyPart.toLowerCase();

    if (bodyPart.includes('abs') || bodyPart.includes('abdominals')) {
      return abs;
    } else if (bodyPart.includes('lats') || bodyPart.includes('back') || bodyPart.includes('lower back') || bodyPart.includes('middle back')) {
      return back;
    } else if (bodyPart.includes('biceps')) {
      return biceps;
    } else if (bodyPart.includes('calves')) {
      return calves;
    } else if (bodyPart.includes('chest')) {
      return chest;
    } else if (bodyPart.includes('forearms')) {
      return forearms;
    } else if (bodyPart.includes('abductors') || bodyPart.includes('adductors') || bodyPart.includes('glutes')) {
      return glutes;
    } else if (bodyPart.includes('hamstrings') || bodyPart.includes('hamstring') || bodyPart.includes('hams') || bodyPart.includes('legs')) {
      return hamstring;
    } else if (bodyPart.includes('quadriceps') || bodyPart.includes('quads') || bodyPart.includes('legs')) {
      return quads;
    } else if (bodyPart.includes('shoulders') || bodyPart.includes('delt') || bodyPart.includes('deltoid') || bodyPart.includes('deltoids')) {
      return shoulder;
    } else if (bodyPart.includes('neck') || bodyPart.includes('traps') || bodyPart.includes('trap')) {
      return traps;
    } else if (bodyPart.includes('triceps') || bodyPart.includes('tri')) {
      return triceps;
    } else if (bodyPart.includes('cardio') || bodyPart.includes('liss') || bodyPart.includes('hiit') ) {
      return heart;
    }

    return fullbody;
  };

  return (
    <img 
      src={getImageForBodyPart(bodyPart)} 
      alt={bodyPart} 
      style={ size == 'small' ? 
        { width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px', border: '0.5px solid #006161', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 'margin-right' : '10px' }
        :
        {
          width: 100,
          height: 100,
          borderRadius: '8px',
          border: '0.5px solid #006161',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          marginRight: '16px',
          backgroundColor: '#ffffff',
          objectFit: 'contain'
        }
      }
    />
  );
};

export default BodyPartImage;