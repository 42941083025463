import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { CircularProgress, Box } from '@mui/material';

const FirebaseGif = ({ exercise, size, maxHeight = 350 }) => {
  const [gifUrl, setGifUrl] = useState(null);
  const storage = getStorage();

  useEffect(() => {
    const fetchGif = async () => {
      try {
        let gifPath = null;

        // Determine the GIF path based on the size and available GIFs
        if (size === 'xlarge' && exercise.xlargeGifs?.length > 0) {
          gifPath = exercise.xlargeGifs[0];
        } else if ((size === 'xlarge' || size === 'large') && exercise.largeGifs?.length > 0) {
          gifPath = exercise.largeGifs[0];
        } else if ((size === 'xlarge' || size === 'large' || size === 'medium') && exercise.mediumGifs?.length > 0) {
          gifPath = exercise.mediumGifs[0];
        } else if (exercise.smallGifs?.length > 0) {
          gifPath = exercise.smallGifs[0];
        }

        if (gifPath) {
          const gifRef = ref(storage, `logbook_gifs/${gifPath}`);
          const url = await getDownloadURL(gifRef);
          console.log("xxxxx",url)
          setGifUrl(url);
        }
      } catch (error) {
        console.error('Error loading GIF:', error);
      }
    };

    fetchGif();
  }, [exercise, size, storage]);

  if (!gifUrl) {
    return <CircularProgress />;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: '100%',
        maxHeight: `${maxHeight}px`,
        overflow: 'hidden',
        userSelect: 'none', // Prevent selection of the image
      }}
      onContextMenu={(e) => e.preventDefault()} // Disable right-click to prevent saving
    >
      {/* The GIF */}
      <img
        src={gifUrl}
        alt={exercise.name}
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: `${maxHeight}px`,
          objectFit: 'contain',
          pointerEvents: 'none', // Disable click
        }}
        draggable="false" // Disable dragging
      />

      {/* Watermark */}
      <Box
        sx={{
          position: 'relative',
          bottom: '150px',
          right: '10px',
          color: 'rgba(0, 0, 0, 0.4)',
          fontWeight: 'bold',
          fontSize: '24px',
          pointerEvents: 'none', // Make watermark non-interactive
        }}
      >
        PR Logs
      </Box>
    </Box>
  );
};

export default FirebaseGif;