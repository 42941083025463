import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, CircularProgress, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../utils/firebase'; // Import Firebase configuration
import ShareIcon from '@mui/icons-material/Share';
import BodyPartImage from './components/BodyPartImage'; // Reuse the component for body part images

const PopularWorkouts = () => {
  const [popularWorkouts, setPopularWorkouts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch popular workouts from Firebase
  useEffect(() => {
    const fetchPopularWorkouts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "popular_workouts")); // Fetch from the popular_workouts collection
        const workoutsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPopularWorkouts(workoutsData.slice(0, 5)); // Fetch only 5 popular workouts
      } catch (error) {
        console.error("Error fetching popular workouts: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPopularWorkouts();
  }, []);

  const handleShareClick = (workoutId) => {
    const shareData = {
      title: 'Check out this workout!',
      text: 'Check out this workout on PR Logs!',
      url: `${window.location.origin}/workout-plans/${workoutId}`,
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(shareData.url)
        .then(() => alert('Link copied to clipboard'))
        .catch((err) => alert('Failed to copy link'));
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box mt="80px" sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography 
        variant="h4" 
        component="h1" 
        mb={4} 
        textAlign="center" 
        sx={{ fontWeight: 'bold' }}
      >
        Most Popular Workouts
      </Typography>

      {popularWorkouts.length === 0 ? (
        <Typography variant="h6" textAlign="center" color="error">
          No popular workouts found.
        </Typography>
      ) : (
        popularWorkouts.map((workout) => (
          <Link to={`/workout-plans/${workout.id}`} style={{ textDecoration: 'none' }} key={workout.id}>
            <Card 
              sx={{ 
                marginBottom: '20px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
                borderRadius: '12px',
                '&:hover': { boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)' },
              }}
            >
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                  <Box display="flex" alignItems="center">
                    {/* <BodyPartImage bodyPart={workout.name} /> */}
                    <Box ml={2}>
                      <Typography variant="h5" component="h2" sx={{ color: '#008080', fontWeight: 'bold' }}>
                        {workout.name}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#555' }}>
                        {workout.description || 'No description available'}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton 
                    onClick={(e) => {
                      e.preventDefault(); // Prevent navigating to the workout details when clicking the share button
                      handleShareClick(workout.id);
                    }} 
                    sx={{ 
                      backgroundColor: '#008080', 
                      color: '#fff', 
                      padding: '10px',
                      '&:hover': { backgroundColor: '#006666' }, 
                      borderRadius: '50%', 
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Box>
                <Typography variant="body2" sx={{ color: '#888' }}>
                  {workout.days} Days
                </Typography>
              </CardContent>
            </Card>
          </Link>
        ))
      )}
    </Box>
  );
};

export default PopularWorkouts;