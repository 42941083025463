import React from 'react';
import { Box, Typography, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import FirebaseGif from '../workoutPlans/components/FirebaseGif'; // Import the FirebaseGif component

const ExerciseDetailView = ({ exercise, isOpen, handleClose }) => {
  if (!exercise) {
    return null;
  }

  const {
    name = 'Unnamed Exercise',
    difficulty = 'Unknown',
    movement = 'Unknown',
    primaryMuscles = [],
    secondaryMuscles = [],
    equipment = [],
    instructions = [],
    largeGifs = [],
    mediumGifs = [],
    smallGifs = [],
  } = exercise;

  // Select the most appropriate GIF URL
  const gifUrl = largeGifs.length > 0 
    ? largeGifs[0]
    : mediumGifs.length > 0 
    ? mediumGifs[0] 
    : smallGifs.length > 0 
    ? smallGifs[0] 
    : null;

  const primaryMusclesList = Array.isArray(primaryMuscles) ? primaryMuscles.join(', ') : 'N/A';
  const secondaryMusclesList = Array.isArray(secondaryMuscles) ? secondaryMuscles.join(', ') : 'N/A';
  const equipmentList = equipment
  const instructionsList = Array.isArray(instructions) ? instructions : [];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullScreen={window.innerWidth < 600} // Fullscreen on mobile, modal on desktop
    >
      <DialogTitle id="scroll-dialog-title">{name}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description">
          {/* FirebaseGif Component */}
          <Box sx={{
    textAlign: 'center',
    maxHeight: 350, // Ensures max height for the box
    height: 350, // Set a fixed height for the image container
    overflow: 'hidden', // Hide any overflow to prevent stretching
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Center the GIF
  }}>
            {gifUrl ? (

                <FirebaseGif
                    exercise={exercise}
                    size={window.innerWidth < 600 ? 'medium' : 'xlarge'}
                  />

            //   <FirebaseGif urlString={gifUrl} altText={name} maxHeight={300} />
            ) : (
              <Typography variant="body2" color="#999" sx={{ padding: '16px' }}>
                No animation available
              </Typography>
            )}
          </Box>

          {/* Exercise Details */}
          <Box
            sx={{
              borderRadius: '16px',
              padding: '16px',
            }}
          >
            {/* Tags for Difficulty and Movement Type */}
{/* Tags for Level (Difficulty) and Force (Movement Type) */}
<Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px', mb: 2 }}>
  {exercise.level && (
    <Chip label={exercise.level} sx={{ backgroundColor: '#006161', color: '#fff' }} />
  )}
  {exercise.force && (
    <Chip label={exercise.force} sx={{ backgroundColor: '#006161', color: '#fff' }} />
  )}
</Box>

{/* Primary and Secondary Muscles */}
{primaryMusclesList && (
  <Box sx={{ mb: 2 }}>
    <Typography variant="h6" color="#006161"> {/* Larger title */}
      Primary Muscles:
    </Typography>
    <Typography variant="body2"> {/* Smaller description */}
      {primaryMusclesList}
    </Typography>
  </Box>
)}

{secondaryMusclesList && (
  <Box sx={{ mb: 2 }}>
    <Typography variant="h6" color="#006161" sx={{ mt: 2 }}> {/* Larger title */}
      Secondary Muscles:
    </Typography>
    <Typography variant="body2"> {/* Smaller description */}
      {secondaryMusclesList}
    </Typography>
  </Box>
)}

{/* Equipment */}
{equipmentList && (
  <Box sx={{ mb: 2 }}>
    <Typography variant="h6" color="#006161"> {/* Larger title */}
      Equipment Used:
    </Typography>
    <Typography variant="body2"> {/* Smaller description */}
      {equipmentList}
    </Typography>
  </Box>
)}

            {/* Instructions */}
            <Box sx={{ textAlign: 'left', mt: 3}}>
              <Typography variant="h6" sx={{ mb: 1 }} color="#006161">
                Instructions:
              </Typography>
              {instructionsList.length > 0 ? (
                <ul style={{ paddingLeft: '20px' }}>
                  {instructionsList.map((instruction, index) => (
                    <li key={index} style={{ marginBottom: '8px' }}>
                      <Typography variant="body2">{instruction}</Typography>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography variant="body2" sx={{ color: '#999' }}>
                  No instructions available
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: '#006161', textTransform: 'none', fontWeight: 'bold' }} startIcon={<DoneIcon />}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseDetailView;