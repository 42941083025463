// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDiUHmVV3IfEp3kpeJZRgzLmwj6VSF-ySE",
    authDomain: "gym-logbook-af2ea.firebaseapp.com",
    projectId: "gym-logbook-af2ea",
    storageBucket: "gym-logbook-af2ea.appspot.com",
    messagingSenderId: "88834750075",
    appId: "1:88834750075:web:4ae58951bf1236c566d4de",
    measurementId: "G-3QT5GQRCG7"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };