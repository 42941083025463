import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const WorkoutPlanMaker = () => {
  const [planName, setPlanName] = useState('');
  const [planDescription, setPlanDescription] = useState('');
  const [workouts, setWorkouts] = useState([]);
  const [firebaseExercises, setFirebaseExercises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [generatedJSON, setGeneratedJSON] = useState(null);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // Track search list visibility
  const [currentDayIndex, setCurrentDayIndex] = useState(null); // Track the day for which the exercise is being added

  const maxCharacterLimit = 50;
  const characterRegex = /[^a-zA-Z0-9\s,-]/g; // Allows letters, numbers, spaces, commas, and hyphens.

  // Fetch exercises from Firebase on component mount
  useEffect(() => {
    const fetchExercises = async () => {
      const querySnapshot = await getDocs(collection(db, "exercises_new"));
      const exercisesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFirebaseExercises(exercisesData);
    };

    fetchExercises();
  }, []);

  const filteredExercises = firebaseExercises.filter(exercise =>
    exercise.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    exercise.primaryMuscles.some(muscle => muscle.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (exercise.secondaryMuscles && exercise.secondaryMuscles.some(muscle => muscle.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const handleInputChange = (setter) => (event) => {
    let value = event.target.value;
    value = value.substring(0, maxCharacterLimit).replace(characterRegex, '');
    setter(value);
  };

  const handleNumberInput = (setter) => (event) => {
    let value = event.target.value.replace(/\D/g, ''); // Remove any non-digit characters.
    setter(value || '0'); // Default to 0 if the string is empty.
  };

  // Add exercise to a specific workout day
  const addExerciseToDay = (exercise) => {
    const exerciseWithSets = {
      ...exercise,
      sets: [{ numReps: 0, weight: 0 }], // Initialize with default set
    };
    
    const updatedWorkouts = [...workouts];
    updatedWorkouts[currentDayIndex].exercises.push(exerciseWithSets);
    setWorkouts(updatedWorkouts);
    
    setIsSearchVisible(false); // Hide search after adding exercise
  };

  const deleteExerciseFromWorkout = (dayIndex, exerciseIndex) => {
    const updatedWorkouts = [...workouts];
    updatedWorkouts[dayIndex].exercises.splice(exerciseIndex, 1);
    setWorkouts(updatedWorkouts);
  };

  // Add a new workout day
  const addNewWorkoutDay = () => {
    setWorkouts([...workouts, { name: `Day ${workouts.length + 1}`, exercises: [] }]);
  };

  const deleteWorkout = (index) => {
    setWorkouts(workouts.filter((_, i) => i !== index));
  };

  // Handle day name change
  const handleDayNameChange = (index, newDayName) => {
    const updatedWorkouts = [...workouts];
    updatedWorkouts[index].name = newDayName;
    setWorkouts(updatedWorkouts);
  };

  const generateJSON = () => {
    const workoutPlan = {
      id: planName.toLowerCase().replace(/\s+/g, '-'),
      name: planName || 'name',
      description: planDescription || 'description',
      days: workouts.length,
      workouts: workouts.map((workout, index) => ({
        id: workout.name.toLowerCase().replace(/\s+/g, '-'),
        name: workout.name,
        exercises: workout.exercises.map((exercise, i) => ({
          exercise: {
            ...exercise, // Copy all properties from the selected exercise
            sets: exercise.sets || [{ numReps: 0, weight: 0 }],
          },
          order: i + 1,
        })),
        notes: '',
      })),
    };

    setGeneratedJSON(JSON.stringify(workoutPlan, null, 2));
  };

  const addSetToExercise = (exerciseIndex, dayIndex) => {
    const updatedWorkouts = [...workouts];
    const exercise = updatedWorkouts[dayIndex].exercises[exerciseIndex];
    exercise.sets.push({ numReps: 0, weight: 0 });
    setWorkouts(updatedWorkouts);
  };

  // Show search and set the current day index for adding exercises
  const handleAddExerciseClick = (dayIndex) => {
    setCurrentDayIndex(dayIndex);
    setIsSearchVisible(true); // Show search list
  };

  return (
    <Box sx={{ display: 'flex', maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      {/* Form on the left side */}
      <Box sx={{ flex: 2, paddingRight: '20px' }}>
        <Typography variant="h4" component="h1" mb={4} textAlign="center">
          Workout Plan Maker
        </Typography>
        <TextField
          fullWidth
          label="Plan Name"
          value={planName}
          onChange={handleInputChange(setPlanName)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          fullWidth
          label="Plan Description"
          value={planDescription}
          onChange={handleInputChange(setPlanDescription)}
          sx={{ marginBottom: '32px' }}
        />

        <Button variant="contained" color="secondary" onClick={addNewWorkoutDay} sx={{ marginBottom: '32px' }}>
          Add Day
        </Button>

        {/* Scrollable list of selected exercises per day */}
        {workouts.map((workout, dayIndex) => (
          <Card key={dayIndex} sx={{ marginBottom: '16px' }}>
            <CardContent>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={10}>
                  {/* Editable day name */}
                  <TextField
                    fullWidth
                    label="Day Name"
                    value={workout.name}
                    onChange={(e) => handleDayNameChange(dayIndex, e.target.value)}
                    sx={{ marginBottom: '16px' }}
                  />
                  <Box sx={{ display: 'flex', gap: '16px', marginTop: '16px', flexWrap: 'wrap' }}>
                    {workout.exercises.map((exercise, i) => (
                      <Card key={i} sx={{ minWidth: '150px', marginBottom: '8px' }}>
                        <CardContent>
                          <Typography variant="body1">{exercise.name}</Typography>
                          <Typography variant="body2">
                            Sets:
                            {exercise.sets.map((set, setIndex) => (
                              <Box key={setIndex} sx={{ display: 'flex', alignItems: 'center', margin: '8px 0' }}>
                                <TextField
                                  type="number"
                                  label="Reps"
                                  value={set.numReps}
                                  onChange={handleNumberInput((value) => {
                                    const updatedWorkouts = [...workouts];
                                    updatedWorkouts[dayIndex].exercises[i].sets[setIndex].numReps = value;
                                    setWorkouts(updatedWorkouts);
                                  })}
                                  sx={{ width: '60px', marginRight: '8px' }}
                                />
                                <TextField
                                  type="number"
                                  label="Weight"
                                  value={set.weight}
                                  onChange={handleNumberInput((value) => {
                                    const updatedWorkouts = [...workouts];
                                    updatedWorkouts[dayIndex].exercises[i].sets[setIndex].weight = value;
                                    setWorkouts(updatedWorkouts);
                                  })}
                                  sx={{ width: '60px' }}
                                />
                              </Box>
                            ))}
                            <IconButton onClick={() => addSetToExercise(i, dayIndex)} aria-label="add-set">
                              <AddIcon />
                            </IconButton>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => deleteWorkout(dayIndex)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {/* Button to add exercise to this day */}
              <Button variant="contained" color="primary" onClick={() => handleAddExerciseClick(dayIndex)} sx={{ marginTop: '16px' }}>
                Add Exercise
              </Button>
            </CardContent>
          </Card>
        ))}

        <Button variant="contained" color="success" onClick={generateJSON}>
          Generate JSON
        </Button>

        {generatedJSON && (
          <Box mt={4}>
            <Typography variant="h6">Generated Workout Plan</Typography>
            <pre>{generatedJSON}</pre>
          </Box>
        )}
      </Box>

      {/* Exercise List on the right side */}
      {isSearchVisible && (
        <Box sx={{ flex: 1, paddingLeft: '20px', maxHeight: '700px', display: 'flex', flexDirection: 'column' }}>
          <TextField
            fullWidth
            label="Search Exercises"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: '16px', position: 'sticky', top: 0, zIndex: 1, background: '#fff' }}
          />

          {/* Scrollable exercise list */}
          <Box sx={{ flex: 1, overflowY: 'auto', paddingRight: '10px' }}>
            {filteredExercises.map((exercise) => (
              <Card
                key={exercise.id}
                sx={{
                  marginBottom: '16px',
                  cursor: 'pointer',
                  backgroundColor: 'white',
                }}
                onClick={() => addExerciseToDay(exercise)}
              >
                <CardContent>
                  <Typography variant="h6">{exercise.name}</Typography>
                  <Typography variant="body2">Category: {exercise.category}</Typography>
                  <Typography variant="body2">Primary Muscles: {exercise.primaryMuscles.join(', ')}</Typography>
                  <Typography variant="body2">Secondary Muscles: {exercise.secondaryMuscles?.join(', ')}</Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WorkoutPlanMaker;
