import React, { useState } from 'react';
import { Box } from '@mui/material';

import HeroBanner from '../components/HeroBanner';
import PopularWorkouts from '../modules/workoutPlans/Popularworkouts.js'

const Home = () => {

  return (
    <Box>
      <HeroBanner />
      <PopularWorkouts />
    </Box>
  );
};

export default Home;