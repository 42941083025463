import React from 'react';
import { Box, Stack, Typography, Link, Button } from '@mui/material';
import Logo from '../assets/images/Logo.png';
import { Instagram } from '@mui/icons-material';
import { Apple } from '@mui/icons-material';

const Footer = () => (

  <Box mt="80px">

    <Stack
      direction="row"
      gap="20px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}
      flexWrap="wrap"
      px="30px"
    >


<Link href="https://www.instagram.com/prlogs.official/" target="_blank" rel="noopener" color="inherit">
        <Instagram />
      </Link>
<Link href="/terms-and-conditions" color="inherit">Terms and Conditions</Link>
      <Link href="/privacy-policy" color="inherit">Privacy Policy</Link>
    
      <Button variant='contained' startIcon={<Apple />} sx={{backgroundColor: '#006161', padding: '10px'}} href="https://apps.apple.com/app/pr-logs-better-workout-notes/id6566186250">Download Now</Button>
    </Stack>

    {/* Other Links (Terms and Conditions, Privacy Policy) */}
    <Stack
      direction="row"
      gap="20px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}
      flexWrap="wrap"
      px="40px"
    >

<img src={Logo} alt="logo" style={{ width: '40px', height: '40px' }} />

<Typography
  variant="h6"
  sx={{ fontSize: { lg: '12px', xs: '16px' } }}
  mt="41px"
  textAlign="center"
>
PR Logs: Better workout Notes. All rights reserved. © {new Date().getFullYear()} 
</Typography>

    </Stack>

    {/* Footer Message */}
    <Typography
      variant="h6"
      sx={{ fontSize: { lg: '22px', xs: '16px' } }}
      mt="41px"
      textAlign="center"
      pb="40px"
    >
      Thank you for showing interest. Better things will keep coming, please follow.❤️
    </Typography>
  </Box>
);

export default Footer;