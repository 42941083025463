import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Box} from '@mui/material';
import './App.css';

import ExerciseDetail from './pages/ExerciseDetail';
import Home from './pages/Home';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import WorkoutPlans from './modules/workoutPlans/workoutPlans'; 
import WorkoutPlanDetail from './modules/workoutPlans/workoutPlanDetail';
import WorkoutPlanMaker from './modules/workoutPlans/WorkoutPlanMaker';



const App = () => {
  return (
    <Box width="400px" sx={{width: { xl: '1488px'}}} m="auto">
        <Navbar/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/exercise/:id" element={<ExerciseDetail/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/workout-plans" element={<WorkoutPlans />} />
            <Route path="/workout-plans/:id" element={<WorkoutPlanDetail />} /> {/* New Detail Page */}
            <Route path="/private/create-plan" element={<WorkoutPlanMaker />} />


        </Routes>
        <Footer/>
    </Box>
  )
}

export default App
