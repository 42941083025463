import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Box } from '@mui/material';

import Logo from '../assets/images/Logo.png';

const Navbar = () => {
  return (
    <Box
      sx={{
        top: '0',
        width: '100%',
        zIndex: 1000,
        background: 'linear-gradient(135deg, #e3ffff 0%, #a0f0f0 100%)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          maxWidth: { xl: '1488px' },  // Max width for larger screens
          margin: { xl: '0 auto' },  // Center the content on large screens
          padding: '10px 20px',  // Padding on normal screens
          boxSizing: 'border-box',
        }}
      >
        <Link to="/">
          <img src={Logo} alt="logo" style={{ width: '48px', height: '48px' }} />
        </Link>
        <Stack 
          direction="row"
          gap="40px"
          fontSize="24px"
          alignItems="center"
        >
          <Link 
            to="/" 
            style={{ 
              textDecoration: 'none', 
              color: '#006161', 
              fontWeight: 'bold', 
              transition: 'color 0.3s ease-in-out' 
            }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#005050'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#006161'}
          >
            Home
          </Link>
          <Link 
            to="/workout-plans" 
            style={{ 
              textDecoration: 'none', 
              color: '#006161', 
              fontWeight: 'bold', 
              transition: 'color 0.3s ease-in-out' 
            }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#005050'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#006161'}
          >
            Workout Plans
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Navbar;