import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Card, CardContent, IconButton, CircularProgress, Grid, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy, startAfter, limit } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import ShareIcon from '@mui/icons-material/Share';
import BodyPartImage from './components/BodyPartImage';
import GoogleAd from '../../components/GoogleAd';

const WorkoutPlans = () => {
  const [workoutPlans, setWorkoutPlans] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [copySuccess, setCopySuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [lastDoc, setLastDoc] = useState(null);
  const [isEndOfList, setIsEndOfList] = useState(false);

  useEffect(() => {
    fetchWorkoutPlans();
  }, []);

  const fetchWorkoutPlans = async (isLoadMore = false) => {
    try {
      const collectionRef = collection(db, 'general_workouts');
      let q = query(collectionRef, orderBy('name'), limit(15));

      if (isLoadMore && lastDoc) {
        q = query(collectionRef, orderBy('name'), startAfter(lastDoc), limit(15));
      }

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setIsEndOfList(true);
        return;
      }

      const newPlans = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      const fetchedCategories = ['All', ...new Set(newPlans.map(plan => plan.category))];
      setCategories(fetchedCategories);

      setWorkoutPlans(prevPlans => isLoadMore ? [...prevPlans, ...newPlans] : newPlans);
    } catch (error) {
      console.error('Error fetching workout plans:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMorePlans = () => {
    if (!isEndOfList) {
      fetchWorkoutPlans(true);
    }
  };

  const handleShareClick = (planId) => {
    const shareData = {
      title: 'PR Logs Workout Plan',
      text: 'Check out this workout plan on PR Logs!',
      url: `${window.location.origin}/workout-plans/${planId}`,
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard.writeText(shareData.url)
        .then(() => alert('Link copied to clipboard'))
        .catch((err) => alert('Failed to copy link'));
    }
  };

  const filteredPlans = workoutPlans.filter(plan => {
    const matchesCategory = selectedCategory === 'All' || plan.category.toLowerCase() === selectedCategory.toLowerCase();
    const matchesSearch = plan.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      plan.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      plan.description?.toLowerCase().includes(searchTerm.toLowerCase());

    return matchesCategory && matchesSearch;
  });

  return (
    <Box mt="80px" sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" component="h1" mb={4} textAlign="center" sx={{ fontWeight: 'bold' }}>
        Workout Plans
      </Typography>

      {/* Search Bar */}
      <Box mb={4} textAlign="center">
        <TextField
          fullWidth
          placeholder="Search workout plans..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ maxWidth: '600px', marginBottom: '16px' }}
        />
      </Box>

      {/* Filters Section */}
      <Box mb={4} textAlign="center">
        {categories.map(category => (
          <Button
            key={category}
            variant={category === selectedCategory ? 'contained' : 'outlined'}
            onClick={() => setSelectedCategory(category)}
            sx={{ margin: '5px', backgroundColor: category === selectedCategory ? '#006161' : 'transparent', color: category === selectedCategory ? '#fff' : '#006161' }}
          >
            {category}
          </Button>
        ))}
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {copySuccess && (
            <Box textAlign="center" mt={2} mb={2}>
              <Typography variant="body1" color="success">
                {copySuccess}
              </Typography>
            </Box>
          )}
          <Grid container spacing={3}>
            {filteredPlans.map((plan, index) => (
              <React.Fragment key={plan.id}>
                <Grid item xs={12} sm={6} md={4}>
                  <Link to={`/workout-plans/${plan.id}`} style={{ textDecoration: 'none' }}>
                    <Card
                      sx={{
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '12px',
                        '&:hover': { boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)' },
                        height: { xs: 'auto', sm: '700px' }, // Flexible height for mobile, fixed for larger screens
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                          <Box display="flex" alignItems="center">
                            <BodyPartImage bodyPart={plan.name} />
                            <Box ml={2}>
                              <Typography variant="h5" component="h2" sx={{ color: '#006161', fontWeight: 'bold' }}>
                                {plan.name}
                              </Typography>
                              <Typography variant="body1" sx={{ color: '#555' }}>
                                {plan.description}
                              </Typography>
                            </Box>
                          </Box>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              handleShareClick(plan.id);
                            }}
                            sx={{
                              backgroundColor: '#006161',
                              color: '#fff',
                              padding: '10px',
                              '&:hover': { backgroundColor: '#006666' },
                              borderRadius: '50%',
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                        </Box>
                        <Typography variant="body2" sx={{ color: '#888' }}>
                          {plan.days} Days
                        </Typography>
                        <Box mt={2}>
                          {plan.workouts.map((workout) => (
                            <Box
                              key={workout.id}
                              display="flex"
                              alignItems="center"
                              sx={{ marginBottom: '8px' }}
                            >
                              <BodyPartImage bodyPart={workout.name} size='small' />
                              <Typography variant="body1" sx={{ fontWeight: 'bold', ml: 1 }}>
                                {workout.name}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>

                {/* Insert Google Ad component after every 4th plan */}
                {(index + 1) % 3 === 0 && (
                  <Grid item xs={12}>
                    <GoogleAd /> {/* Your Google Ad component */}
                  </Grid>
                )}
              </React.Fragment>
            ))}
          </Grid>

          {!isEndOfList && (
            <Box textAlign="center" mt={4}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#006161', color: '#fff' }}
                onClick={loadMorePlans}
              >
                Load More
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default WorkoutPlans;