import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Card, CardContent, CircularProgress } from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import GoogleAd from '../../components/GoogleAd';
import ShareIcon from '@mui/icons-material/Share';
import FirebaseGif from '../workoutPlans/components/FirebaseGif'; // Import the FirebaseGif component
import ExerciseDetailView from './ExerciseDetailView'; // Component for exercise detail view

const WorkoutPlanDetail = () => {
  const { id } = useParams();
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedExercise, setSelectedExercise] = useState(null); // To track selected exercise for detail view
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state for larger screens

  useEffect(() => {
    const fetchWorkoutPlanById = async () => {
      try {
        const q = query(collection(db, 'general_workouts'), where('id', '==', id));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          setPlan({ id: docSnap.id, ...docSnap.data() });
        } else {
          setPlan(null);
        }
      } catch (error) {
        console.error('Error fetching workout plan: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkoutPlanById();
  }, [id]);

  const handleShareClick = () => {
    const shareData = {
      title: 'PR Logs Workout Plan',
      text: 'Check out this workout plan on PR Logs!',
      url: `${window.location.href}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      navigator.clipboard
        .writeText(shareData.url)
        .then(() => alert('Link copied to clipboard'))
        .catch((err) => alert('Failed to copy link'));
    }
  };

  const openExerciseDetail = (exercise) => {
    setSelectedExercise(exercise);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedExercise(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  if (!plan) {
    return (
      <Box
        mt="80px"
        sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto', textAlign: 'center' }}
      >
        <Typography variant="h4" component="h1" mb={4}>
          Workout Plan Not Found
        </Typography>
        <Typography variant="body1">
          The workout plan you are looking for does not exist or has been removed.
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt="80px" sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <Typography variant="h4" component="h1" mb={4} textAlign="center">
        {plan.name}
      </Typography>

      {/* Display category/tag */}
      <Box textAlign="center" mb={4}>
        <Typography variant="body2" color="primary">
          {plan.category}
        </Typography>
      </Box>

      {/* Share Button */}
      <Button
        size="small"
        variant="contained"
        sx={{ backgroundColor: '#006161', color: '#fff', marginBottom: '20px' }}
        startIcon={<ShareIcon />}
        onClick={handleShareClick}
      >
        Share
      </Button>

      {/* Plan Details */}
      <Typography variant="body1" sx={{ marginBottom: '12px', color: '#555' }}>
        {plan.description}
      </Typography>
      <Typography variant="body2" sx={{ color: '#888', marginBottom: '24px' }}>
        {plan.days} Days
      </Typography>

      {/* Workouts with Animations */}
      {plan.workouts.map((workout, index) => (
        <Box key={workout.id} mb={4}>
          <Typography
            variant="h5"
            component="h2"
            sx={{ color: '#006161', fontWeight: 'bold', marginBottom: '12px' }}
          >
            {workout.name}
          </Typography>

          {/* Show workout notes if they exist */}
          {workout.notes && (
            <Typography
              variant="body2"
              sx={{ marginBottom: '16px', fontStyle: 'italic', color: '#666' }}
            >
              {workout.notes}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              gap: '16px',
              paddingBottom: '10px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {workout.exercises.map((exercise, i) => (
              <Card
                key={exercise.exercise.id}
                sx={{
                  width: '250px',
                  margin: '0 auto',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  cursor: 'pointer',
                  flexShrink: 0,
                }}
                onClick={() => openExerciseDetail(exercise)}
              >
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {/* Use FirebaseGif component */}
                  <FirebaseGif
                    exercise={exercise.exercise}
                    size={window.innerWidth < 600 ? 'small' : 'medium'}
                  />

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {i + 1}. {exercise.exercise.name}
                    </Typography>

                    <Typography variant="body2" sx={{ color: '#888' }}>
                      {exercise.exercise.category === 'Cardio'
                        ? `${exercise.exercise.sets?.[0]?.duration || 0} min`
                        : `${exercise.exercise.sets?.length || 0} Sets`}
                    </Typography>

                    {/* Show exercise notes or description */}
                    <Typography variant="body2" sx={{ color: '#777', fontStyle: 'italic' }}>
                      {exercise.exercise.notes || exercise.exercise.instructions?.[0] || ''}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>

          {/* Google Ads */}
          {index < plan.workouts.length - 1 && (
            <Box mt={4} mb={4} sx={{ textAlign: 'center' }}>
              <GoogleAd />
            </Box>
          )}
        </Box>
      ))}

      {/* Exercise Detail Modal */}
      <ExerciseDetailView
        exercise={selectedExercise?.exercise}
        isOpen={isModalOpen}
        handleClose={closeModal}
      />
    </Box>
  );
};

export default WorkoutPlanDetail;