
import React from 'react';
import { Box } from '@mui/material';
import './css/termsandconditions.css';
import HeroBanner from '../components/HeroBanner';


const PrivacyPolicy = () => {
    return (
      <Box mt="80px">
        <div style={{ height: '100vh', overflow: 'auto' }}>
          <embed 
            src="https://prlogs-publicresources.s3.ap-south-1.amazonaws.com/privacyPolicy.pdf" 
            type="application/pdf" 
            width="100%" 
            height="100%" 
            style={{ minHeight: '500px', maxHeight: '1000px' }}
          />
        </div>
      </Box>
    );
  };

export default PrivacyPolicy;
