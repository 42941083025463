import React, { useEffect } from 'react';

const GoogleAd = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (e) {
            console.error("AdsbyGoogle error:", e);
          }
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
     data-ad-client="ca-pub-2523342136490572"
     data-ad-slot="8071749178"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
}

export default GoogleAd;